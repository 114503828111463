<template>

  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-light width-available text-center">
        <strong>
          Frequently Asked Questions
        </strong>
      </div>
    </section>
    <div class="px-2">
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#1" aria-expanded="false" aria-controls="1">
          <strong>How do I Register?</strong>
        </div>
        <div class="promo-content text-light collapse p-2 show" id="1">
          <!--Some html static content-->
          Simply SMS the word <strong>'JOIN' to 23511</strong>. This process is completely FREE.<br><br>
          Alternatively, open Sparkpesa website and click on <strong>"REGISTER"</strong> and then fill in the required details to complete your registration.<br><br>
          Registration is absolutely <strong>FREE</strong>.
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#3" aria-expanded="false" aria-controls="3">
          <strong>How do I reset my password?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="3">
          <!--Some html static content-->
          If you do not remember your password, select <strong>'Login'</strong> then click on <strong>'Reset Password'</strong> Enter your 
          account number and click on 'Get Reset Code'. You will receive a reset password code via text message. Input the confirmation code then set a new password for your account.
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#4" aria-expanded="false" aria-controls="4">
          <strong>How do I deposit money into my account?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="4"> 
          <!--Some html static content-->
          To deposit while online, click on the green bar at the top of the website page. You will be directed to the deposit page, select the amount you wish to deposit or enter the amount then click '<strong>Pay</strong>'. Wait for an  M-PESA pop-up notification, confirm the details then enter your M-PESA pin to complete the transaction.<br>
          You will receive a message notification from us informing you of a successful deposit.
          <br><br>
          To deposit offline, follow the steps bellow:<br><br>

          1)  Open <strong>M-PESA</strong> from your SIM toolkit<br>
          2)  Go to <strong>LIPA NA M-PESA</strong><br>
          3)  Select <strong>PAY BILL</strong><br>
          4)  Enter <strong>1000</strong> as business number<br>
          5)  Account number is <strong>Sparkpesa</strong><br>
          6)  Enter the amount<br>
          7)  Put your <strong>M-PESA PIN</strong> to complete the process

        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#5" aria-expanded="false" aria-controls="5">
          <strong>What is the minimum amount I can deposit?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="5">
          <!--Some html static content-->
          Minimum deposit is 1/=
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#6" aria-expanded="false" aria-controls="6">
          <strong>What are the deposit charges?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="6">
          <!--Some html static content-->
          Kindly note that the deposit charges are applied as per your mobile money service provider.

        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#7" aria-expanded="false" aria-controls="7">
          <strong>Can I deposit using a different phone number?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="7">
          <!--Some html static content-->
         No. Only the phone number associated with your Sparkpesa account can be used to add money into the account.
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#8" aria-expanded="false" aria-controls="7">
          <strong>How do I check my account balance?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="8">
          <!--Some html static content-->
         Send the word 'BALANCE' to <strong>23511</strong> to check your balance.<br><br>
         Alternatively, log into into your account to check balance.
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#9" aria-expanded="false" aria-controls="8">
          <strong>How do I withdraw money from my account?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="9">
          <!--Some html static content-->
          Simply click on <strong>'My Account'</strong> from the left menu then select <strong>'withdraw'</strong>, enter the amount you wish to withdraw then again click on <strong>'Withdraw'</strong>.<br><br>
          To withdraw through SMS, send a text to <strong>23511</strong> using the format;<strong> W#Amount.</strong>

        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#10" aria-expanded="false" aria-controls="9">
          <strong>What is the minimum withdrawal amount?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="10">
          <!--Some html static content-->
          Sparkpesa allows you to withdraw a minimum of 100/=
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#11" aria-expanded="false" aria-controls="10">
          <strong>What is the maximum withdrawal limit?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="11">
          <!--Some html static content-->
          You can withdraw up to 300,000/= per day from your Sparkpesa account
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#12" aria-expanded="false" aria-controls="11">
          <strong>How long does it take to proceess my withdrawal request?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="12">
          <!--Some html static content-->
          Withdrawals are almost instant. Kindly contact us if you do not receive your money instantly.
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#13" aria-expanded="false" aria-controls="12">
          <strong>What are the withdrawal charges?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="13">
          <!--Some html static content-->
          M-PESA withdrawal charges will apply. Kshs 16/= for amounts 1000/= and below and Kshs 23 for amounts above 1000/=
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#14" aria-expanded="false" aria-controls="13">
          <strong>Can I withdraw to another number?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="14">
          <!--Some html static content-->
          No. Money withdrawn is always and only received by the account number registered with Sparkpesa.
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#15" aria-expanded="false" aria-controls="14">
          <strong>I'm below 18 years old, can I join Sparkpesa?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="15">
          <!--Some html static content-->
          No. Betting and gambling activity is strictly restricted to individuals of 18 and above.
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#16" aria-expanded="false" aria-controls="15">
          <strong>What is the 20% withholding tax?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="16">
          <!--Some html static content-->
        Sparkpesa, as are all betting firms in Kenya, are required to remit 20% of a player's winnings to the revenue authorities.
        This has been in effect since the signing into law of the Finance Bill 2020.
        As a result, a player winning 50,000/= will receive 40,000/= only in their Sparkpesa account with the remaining 10,000/= held as witholding tax. <br>

        <strong> A.	EXCISE TAX </strong><br>
        Excise tax rate is 7.5% levied on stake. Any stake whether in. <br>
        1. Bonus form-from bonus account <br>
        2. Free bets offered <br>
        3. Real money stake is subject to 7.5% taxation calculated as below.<br><br>

        Stake Tax= STAKE – [STAKE AFTER TAX] <br>
        Stake after Tax= 100/1.075=93.02<br><br>

        Example: You wager with Kshs 100 <br>
        Stake after Tax:100÷1.075=93.02 <br>
        Excise duty=100-93.02=6.98 <br>
        Therefore, this is deducted from the players money, in this case it is Kshs 6.98<br><br>

        <strong>B.	Withholding Tax </strong><br>
        Withholding Tax is 20% of a player’s winnings. <br>
        Example<br>
        Stake=93.02 <br>
        Odds=10 <br>
        Gross winnings=93.02×10= 930.2 <br>
        Actual winnings =930.2-93.02=837.18 <br>
        Withholding tax is 20% of 837.18= Kshs 167.436<br>






        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#17" aria-expanded="false" aria-controls="16">
          <strong>Where can I find your Terms and Conditions?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="17">
          <!--Some html static content-->
          Go to menu and locate 'Terms and Conditions'. You can as well access the T&C's from the bottom(footer) of the homepage.
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#18" aria-expanded="false" aria-controls="17">
          <strong>How can I contact Sparkpesa?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="18">
          <!--Some html static content-->
          Kindly call our 24 hour dedicated customer support team on <strong>+254712895599 / +254701895544</strong><br><br>

          Reach as through email as well at: <a href="support@sparkpesa.co.ke"> support@sparkpesa.co.ke </a> <br><br>
 
        </div>
      </section>



      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#19" aria-expanded="false" aria-controls="18">
          <strong>What is Sparkpesa Karibu Gift?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="19">
          <!--Some html static content-->
         Sparkpesa Karibu Gift is a welcome gift for all new customers. <br><br>
         The gift is used to Top-up your Bonus Account every time you Bet until the gift is fully used. <br><br>
         <strong>How do I enjoy Karibu Gift?</strong>
         To use your Karibu Gift, follow the below steps: <br> <br>
         <ul>
           <li>
             Register on Sparkpesa.
           </li>
           <li>
             Deposit funds into your Sparkpesa account.
           </li>
           <li>
             Place a cash bet of 10/= and above.
           </li>
         </ul>
         After placing your bet, 10% of your Bet Amount will be instantly Topped Up into your Bonus Account. <br><br>
         EXAMPLE; If you place a bet of Ksh.1000/=, you get Ksh.100/= into your Bonus Account which is 10% of your stake of 1,000 Bob. <br><br>
         Use the Bonus Money to place bets without having to deposit again. <br><br>
         Use the Bonus Money to place bets without having to deposit again. <br>
         <ul>
           <li>
             Minimum stake is Ksh.10/=
           </li>
           <li>
             Minimum odds of 3.5.
           </li>
         </ul>




        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#21" aria-expanded="false" aria-controls="20">
          <strong>How do I place a bet?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="21">
          <!--Some html static content-->
          To place a bet, first login to your account on Sparkpesa website.<br><br>

          Find the sporting event you would like to bet on then make your selection<br><br>

          Locate and click on the betslip at the bottom of your screen, input the stake then click 'place bet'.

        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#22" aria-expanded="false" aria-controls="21">
          <strong>How do I bet using SMS</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="22">
          <!--Some html static content-->
          <ol>
            <li>Single Bets</li>
            To place a single bet,send an SMS text to <strong>23511</strong> using the format: <strong>GAMEID#PICK#AMOUNT e.g 5682#X#100</strong> 
            <li>
                Multi bets
            </li>
           To place a multi bet, send an SMS text to 23511 using the format: <strong>GAMEID#PICK#GAMEID#PICK#GAMEID#PICK#AMOUNT e.g 6384#X3548#2#500</strong>
          </ol>
          SMS BETTING IS ABSOLUTELY FREE!

        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#23" aria-expanded="false" aria-controls="22">
          <strong>What type of bets can I place at Sparkpesa?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="23">
          <!--Some html static content-->
          You can place single bets, multiple bets, system/combined bets and virtual bets.<br>
          You can also combine selections from different sports-football, tennis, basketball, rugby etc. -under one  bet slip.
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#24" aria-expanded="false" aria-controls="23">
          <strong>What is the minimum stake per bet?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="24">
          <!--Some html static content-->
          The minimum stake for both single and multi bets is 20/=
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#25" aria-expanded="false" aria-controls="24">
          <strong>What is the maximum stake per bet?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="25">
          <!--Some html static content-->
          The maximum stake for both single and multi bets is 500,000/=
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#26" aria-expanded="false" aria-controls="25">
          <strong>What is the maximum payout per bet slip?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="26">
          <!--Some html static content-->
          Sparkpesa gives you the chance to win up to 1,000,000/= on every single or multi bets<br>

          Maximum payout per day for a single player is 1,000,000/=

        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#27" aria-expanded="false" aria-controls="25">
          <strong>How many selections can I add to my bet slip?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="27">
          <!--Some html static content-->
          You can add as many as 30 events/selections on your bet slip.

        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#28" aria-expanded="false" aria-controls="26">
          <strong>Can I cancel a bet?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="28">
          <!--Some html static content-->
          You can cancel a bet five (5) minutes after placing that particular bet. This should be before the kick-off time of the earliest match selected in that bet. The maximum number of bets that can be cancelled in a day is three(3).<br><br>

          To cancel a bet on SMS, send <strong>CANCEL#BET ID</strong> to <strong>23511</strong> e.g <strong>CANCEL#4231</strong>
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#29" aria-expanded="false" aria-controls="27">
          <strong>How will I know if my bet has been placed successfully?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="29">
          <!--Some html static content-->
          Look out for a popup notification message displayed on your screen informing whether or not your bet has been placed successfully.<br><br>
          Sparkpesa will also send you an instant bet placement SMS notification once you place your bet.
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#30" aria-expanded="false" aria-controls="28">
          <strong>Why was my bet rejected?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="30">
          <!--Some html static content-->
          Sparkpesa reserves the sole right of refusing bets due to a variety of reasons including but not limited to suspicion of fraudulent activity by a player. Kindly contact us should
          you need clarification on a rejected or cancelled bet.
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#31" aria-expanded="false" aria-controls="29">
          <strong>Why is my bet voided?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="31">
          <!--Some html static content-->
          A voided bet is invalid or nil.<br><br>
          Bets are voided if an event that a player had staked on has been cancelled, postponed, abandoned, etc.
          In this instance a period of 24 hours is allowed for the affected event to be rescheduled and played out. 
          If the event does not take place in the period aforementioned, all bet slips containing the affected event are 
          settled with the said event resulted by the odds of 1 <br><br>

          Kindly contact us if you need clarification on a voided bet.
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#32" aria-expanded="false" aria-controls="30">
          <strong>How do I play in the jackpot?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="32">
          <!--Some html static content-->
          To take a part in our jackpot promotions, simply login and click on 'Jackpot'. Make your jackpot selections 
          and place your jackpot bet. Stake is automatically pre-entered since it is a fixed stake. 
          Kindly confirm that your account balance is equal to or greater than the jackpot stake required.<br><br>

          You can also place your jackpot bet through SMS. Send a text to <strong>23511</strong>  in the following format:
           <strong>JP#PICK#AMOUNT</strong> e.g <strong> JP#1XXX21122XX12#10 </strong>

           To get jackpot games through SMS, send <strong>'JP'</strong> to <strong>23511</strong>

        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#33" aria-expanded="false" aria-controls="31">
          <strong>What is live betting?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="33">
          <!--Some html static content-->
          Live betting is an exciting feature that allows you to place a bet on an event that has already started and is ongoing.
          This feature is very handy for instance if your favourite event has kicked off before you have had a chance to place your bet.<br><br>

          Note that odds on live events change rapidly due to real time occurences in the event.
          
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#34" aria-expanded="false" aria-controls="32">
          <strong>How can I access my bet history?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="34">
          <!--Some html static content-->
          Once logged in to your account, click on <strong>'My bets'</strong> at the bottom of the homepage access your betting history.<br>
          Switch between normal, jackpot, virtual bets, quick pesa  etc.

        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#35" aria-expanded="false" aria-controls="33">
          <strong>What is cash out?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="35">
          <!--Some html static content-->
          Cash out is an interesting feature that allows a player to cancel a bet while still winning on that same bet. 
          This feature is particularly important if a player feels the remaining or ongoing events in his/her bet slip may not result 
          as they had predicted hence leading to a lost bet. Cash out therefore enables the player to cancel the bet while still winning.


        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#36" aria-expanded="false" aria-controls="34">
          <strong>What other offers and promotions does Sparkpesa have?</strong>
        </div>
        <div class="promo-content text-light collapse p-2" id="36">
          <!--Some html static content-->
          Here at Sparkpesa we offer the best value promotions on a frequent basis. These promotions are open for
          participation by each and every one of our players.<br><br>

          For more information and to keep up with the latest Sparkpesa offers and promotions, kindly follow us on our social media pages. Below find links to our Facebook, Twitter and Instagram pages.<br><br><br>

          <strong>Facebook:</strong>  <a href="https://www.facebook.com/Sparkpesa/"> https://www.facebook.com/Sparkpesa/ </a> <br><br>

          <strong>Twitter:</strong>  <a href="https://twitter.com/BetariqKe?s=11"> https://twitter.com/BetariqKe?s=11 </a> <br><br>

          <strong>Instagram:</strong>  <a href="https://instagram.com/sparkpesa?utm_medium=copy_link"> https://instagram.com/sparkpesa?utm_medium=copy_link </a>
        </div>
      </section>
    </div>


    <!-- End Section -->

    <!-- <section class="social-links" style=" ">
       <div class="text-light width-available text-center">
        <strong>
          Frequently Asked Questions
        </strong>
      </div>
    </section> -->
  </div>

</template>

<script>

export default {
  name: 'Faq',
  components: {
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","terms");
    this.reloadProfile();

  }

}
</script>